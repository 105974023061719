import {
  Box,
  Text,
  Image,
  Flex,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { GreenButton } from "../Button";
import TonIcon from "./assets/ton-icon.png";
import { useUserData } from "@/contexts/SubmitRequest";
import PoolIcon from "./assets/pool.png";
import RewardDrawer from "./RewardDrawer";
import NoData from "./NoData";

const Pool = ({ onShowDetail }: { onShowDetail: (pool: any) => void }) => {
  const { stakePools } = useUserData();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      sx={{
        padding: "20px 20px",
      }}
    >
      <Text
        sx={{
          fontSize: "30px",
          fontWeight: 900,
          textAlign: "left",
          color: "#FFF",
        }}
      >
        GET MORE PEPES!
      </Text>
      <Flex
        sx={{
          height: "54px",
          background: "#FBCC34",
          borderRadius: "20px",
          marginTop: "16px",
        }}
        onClick={onOpen}
        align={"center"}
        justify={"center"}
      >
        <Image
          src={PoolIcon}
          sx={{
            width: "30px",
          }}
        ></Image>
      </Flex>
      <Flex
        flexDir={"column"}
        align={"center"}
        sx={{
          width: "100%",
          gap: "20px",
          marginTop: "20px",
        }}
      >
        {stakePools.map((item, index) => {
          return (
            <Box
              onClick={() => {
                onShowDetail(item.poolId);
              }}
              key={index}
              sx={{
                border: "3px solid #000",
                padding: "12px 12px 12px 20px",
                width: "100%",
                background: "#232523",
                borderRadius: "20px",
              }}
            >
              <Flex align={"center"}>
                <Flex
                  sx={{
                    width: "78px",
                    height: "78px",
                    marginRight: "26px",
                    position: "relative",
                  }}
                  align={"center"}
                  justify={"center"}
                >
                  <Flex
                    align={"center"}
                    justify={"center"}
                    sx={{
                      width: "78px",
                      height: "78px",
                      background: "#fff",
                      borderRadius: "50%",
                    }}
                  >
                    <Image
                      src={item.img}
                      sx={{
                        width: "48px",
                        height: "48px",
                      }}
                    ></Image>
                  </Flex>

                  <Image
                    src={TonIcon}
                    sx={{
                      width: "45px",
                      position: "absolute",
                      bottom: "-10px",
                      right: "-10px",
                    }}
                  ></Image>
                </Flex>
                <Box
                  sx={{
                    flex: 1,
                  }}
                >
                  <Text
                    sx={{
                      fontSize: "20px",
                      fontWeight: 900,
                      color: "#fff",
                    }}
                  >
                    {item.name}
                  </Text>
                  <Text
                    sx={{
                      marginTop: "14px",
                      fontSize: "15px",
                      color: "#7C7C7C",
                    }}
                  >
                    Stake $PEPES Get {item.tokenName}
                  </Text>
                  <GreenButton
                    sx={{
                      marginTop: "14px",
                      borderRadius: "10px",
                      height: "31px !important",
                      fontWeight: 900,
                    }}
                  >
                    LIVE
                  </GreenButton>
                </Box>
              </Flex>
            </Box>
          );
        })}

        {stakePools.length === 0 && <NoData />}
      </Flex>
      <RewardDrawer isOpen={isOpen} onClose={onClose}></RewardDrawer>
    </Box>
  );
};
export default Pool;
