import { ButtonProps, Flex, FlexProps, Button } from "@chakra-ui/react";
import styled from "@emotion/styled";

const GreenButtonStyle = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: center;
  background: #fbcc34;
  border-radius: 10px;
  height: 26px;
  font-size: 15px;
  color: #000;
  cursor: pointer;
`;

export const GreenButton = (props: FlexProps) => {
  return <GreenButtonStyle variant={"unstyled"} {...props}></GreenButtonStyle>;
};
