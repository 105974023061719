import { api } from ".";

export const getChargeInfo = async (address: string = "") => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get(`/Charge/ChargeInfo/${address}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// 获取用户所有币种的可领取余额
export const getUserAmountInfo = async () => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get(`/Charge/UserAmounts`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const withdraw = async (data: {
  jettonMaster: string;
  toAddress: string;
  amount: number;
}) => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.post(`/Charge/Withdraw`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getStakePools = async () => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get(`/Charge/ActiveStakePools`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const stake = async (data: { poolId: number; score: number }) => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.post(`/Charge/StakePool`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const chargeWithdraw = async (data: {
  jettonMaster: string;
  toAddress: string;
  amount: number;
}) => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.post(`/Charge/Withdraw`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getStakePoolReward = async (poolId: number) => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get(`/Charge/UserPoolReward/${poolId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
