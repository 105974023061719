import Detail from "@/components/Earn/Detail";
import Pool from "@/components/Earn/Pool";
import Header from "@/components/Earn/Header";
import config from "@/constants/tgConfig";
import { formatAmount } from "@/utils/formatBalance";
import { Box, Image, Text, Flex } from "@chakra-ui/react";
import { getHttpV4Endpoint } from "@orbs-network/ton-access";
import { Address, TonClient4 } from "@ton/ton";
import { useTonAddress } from "@tonconnect/ui-react";
import { useEffect, useState } from "react";
import { StakePool, useUserData } from "@/contexts/SubmitRequest";
import { getUserScore } from "@/api";
import { stake } from "@/api/earn";
import toast from "react-hot-toast";
import RewardDrawer from "@/components/Earn/RewardDrawer";
import ConfirmY from "@/components/Earn/assets/confirm-yy.png";

const Earn = () => {
  // const { address } = useAccount();
  const [showDetail, setShowDetail] = useState(false);
  const [pool, setPool] = useState<StakePool | null>(null);
  const rawAddress = useTonAddress(false);
  const [balance, setBalance] = useState("0");
  const [userScore, setUserScore] = useState(0);
  const [currentPoolId, setCurrentPoolId] = useState(0);
  const { handleGetStakePools, stakePools } = useUserData();

  const handleStake = async (poolId: number, score: number) => {
    try {
      const res = await stake({
        poolId,
        score,
      });
      if (res.code == 200) {
        handleGetStakePools();
        handleUserScore();
        toast(
          <Flex
            align={"flex-start"}
            sx={{
              padding: "0 0 0 12px",
              background: "#FBAE17",
              borderRadius: "10px",
              border: "none !important",
              overflow: "hidden",
              width: "100%",
            }}
          >
            <Flex
              className="test"
              sx={{
                textAlign: "center",
                background: "#232523",
                padding: "12px",
                margin: "0 !important",
                borderRadius: "10px",
                border: "none !important",
                color: "#fff",
                width: "100%",
              }}
              align={"flex-start"}
            >
              <Image
                src={ConfirmY}
                sx={{
                  width: "26px",
                  height: "26px",
                  marginRight: "8px",
                }}
              ></Image>
              <Box
                sx={{
                  flex: 1,
                }}
              >
                <Text
                  sx={{
                    fontSize: "15px",
                    fontStyle: "normal",
                    fontWeight: 900,
                    textAlign: "left",
                    marginTop: "4px",
                  }}
                >
                  Stake successfully
                </Text>
                <Text
                  sx={{
                    fontSize: "13px",
                    textAlign: "left",
                    fontWeight: 500,
                    marginTop: "4px",
                  }}
                >
                  {score} $PEPES Staked
                </Text>
              </Box>
            </Flex>
          </Flex>,
          {
            duration: 3000,
            position: "top-center",
            style: {
              background: "transparent",
              borderRadius: "20px",
              padding: "0 0 0 0",
              margin: "0 !important",
              width: "100%",
              border: "none",
            },
          }
        );
      }
    } catch (e) {
      console.log("stake error", e);
    }
  };

  const handleUserScore = async () => {
    try {
      const res = await getUserScore();
      console.log(res);
      if (res.code === 200) {
        setUserScore(res.data);
      }
    } catch (e) {
      console.log("getUserScore error", e);
    }
  };

  useEffect(() => {
    handleUserScore();
  }, []);

  return (
    <Box
      sx={{
        height: "100%",
        overflow: "auto",
        paddingBottom: "20px",
        boxSizing: "border-box",
      }}
    >
      <Header
        balance={balance}
        showBack={showDetail}
        onBack={() => {
          setShowDetail(false);
          setCurrentPoolId(-1);
        }}
      ></Header>
      {showDetail ? (
        <Detail
          handleStake={handleStake}
          userScore={userScore}
          pool={stakePools.find((pool) => pool.poolId == currentPoolId)}
          onShowDetail={() => {
            setShowDetail(false);
            setCurrentPoolId(-1);
          }}
        ></Detail>
      ) : (
        <Pool
          onShowDetail={(poolId: number) => {
            setShowDetail(true);
            setCurrentPoolId(poolId);
          }}
        ></Pool>
      )}
    </Box>
  );
};

export default Earn;
