import { Box, Flex, SimpleGrid, Image, Text } from "@chakra-ui/react";
import HomeBg from "./assets/bg.png";
import TonIcon from "./assets/ton.png";
import P1Icon from "./assets/p1.png";
import P2Icon from "./assets/p2.png";
import P3Icon from "./assets/p3.png";
import P4Icon from "./assets/p4.png";
import P5Icon from "./assets/p5.png";
import P6Icon from "./assets/p6.png";
import Header from "./Header";
import { getHttpV4Endpoint } from "@orbs-network/ton-access";
import config from "@/constants/tgConfig";
import { Address, TonClient4 } from "@ton/ton";
import { useTonAddress } from "@tonconnect/ui-react";
import { useEffect, useState } from "react";
import { formatAmount } from "@/utils/formatBalance";

const BuyPoint = ({
  balance,
  marketItems,
  totalScore,
  onClose,
  handleBuyPoint,
}: {
  balance: string;
  marketItems: any;
  totalScore: number;
  onClose: () => void;
  handleBuyPoint: (itemId: number, amount: string, point: number) => void;
}) => {
  return (
    <Flex
      sx={{
        height: "100%",
        width: "100%",
        padding: "80px 0 60px",
        overflowY: "auto",
        position: "relative",
        backgroundImage: `url(${HomeBg})`,
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
      }}
      align={"center"}
      flexDir={"column"}
    >
      <Box
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
        }}
      >
        <Header
          balance={balance}
          showBack={true}
          onBack={() => {
            onClose();
          }}
        ></Header>
      </Box>

      <Box
        sx={{
          fontSize: "35px",
          fontWeight: 700,
          marginTop: "20px",
          color: "#fff",
          position: "relative",
        }}
      >
        {Number(totalScore).toLocaleString()}
      </Box>
      <Text
        sx={{
          color: "#7C7C7C",
          textAlign: "center",
          fontFamily: "Avenir",
          fontSize: "20px",
          fontWeight: 500,
        }}
      >
        $PEPES{" "}
      </Text>
      <Flex
        sx={{
          position: "absolute",
          top: "200px",
          left: "0",
          width: "100%",
          minHeight: "calc(100% - 200px)",
          background:
            "linear-gradient(0deg, #000 83.58%, rgba(102, 102, 102, 0.00) 100%)",
          padding: "0 28px",
        }}
        flexDir={"column"}
        align={"center"}
      >
        <SimpleGrid
          columns={3}
          spacing={"20px"}
          spacingY={"40px"}
          sx={{
            width: "100%",
          }}
        >
          {marketItems.map((item: any, index: number) => {
            let icon = P1Icon;
            if (index === 0) {
              icon = P1Icon;
            } else if (index === 1) {
              icon = P2Icon;
            } else if (index === 2) {
              icon = P3Icon;
            } else if (index === 3) {
              icon = P4Icon;
            } else if (index === 4) {
              icon = P5Icon;
            } else if (index === 5) {
              icon = P6Icon;
            }
            return (
              <Box key={index}>
                <Flex
                  bg="#97E181"
                  height="100px"
                  align={"center"}
                  flexDir={"column"}
                  sx={{
                    borderRadius: "18px",
                  }}
                >
                  <Image
                    src={icon}
                    sx={{
                      marginTop: "10px",
                      height: "52px",
                    }}
                  ></Image>
                  <Text
                    sx={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginTop: "6px",
                    }}
                  >
                    PEPES
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "#fff",
                        marginLeft: "2px",
                      }}
                    >
                      X{item.score}
                    </span>
                  </Text>
                </Flex>
                <Flex
                  sx={{
                    width: "100%",
                    height: "28px",
                    padding: "5px 25px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                    border: "1px solid #97E181",
                    background: "#FFF",
                    marginTop: "4px",
                  }}
                  onClick={() => {
                    handleBuyPoint(item.id, item.price, item.score);
                  }}
                >
                  <Image
                    src={TonIcon}
                    sx={{
                      width: "14px",
                      height: "14px",
                    }}
                  ></Image>
                  <Text
                    sx={{
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: 900,
                      marginLeft: "2px",
                    }}
                  >
                    {item.price}
                  </Text>
                </Flex>
              </Box>
            );
          })}
        </SimpleGrid>
      </Flex>
    </Flex>
  );
};

export default BuyPoint;
