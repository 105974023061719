import NoDataImg from "./assets/nodata.png";
import { Text, Image, Flex } from "@chakra-ui/react";
const NoData = () => {
  return (
    <Flex
      flexDir={"column"}
      align={"center"}
      justify={"center"}
      sx={{
        marginTop: "10vh",
      }}
    >
      <Image
        src={NoDataImg}
        alt="no data"
        sx={{
          width: "75px",
        }}
      />
      <Text
        sx={{
          fontSize: "25px",
          fontStyle: "normal",
          fontWeight: 700,
          marginTop: "16px",
          color: "#fff",
        }}
      >
        No pool yet
      </Text>
    </Flex>
  );
};

export default NoData;
