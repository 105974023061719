import {
  Box,
  Text,
  Image,
  Flex,
  Input,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  Slider,
  SliderThumb,
} from "@chakra-ui/react";
import { GreenButton } from "../Button";
import { useEffect, useState } from "react";
import { getHttpV4Endpoint } from "@orbs-network/ton-access";
import TonIcon from "./assets/ton-icon.png";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import {
  getChargeInfo,
  getStakePoolReward,
  getUserAmountInfo,
  stake,
  withdraw,
} from "@/api/earn";
import { accDiv, accMul, toFixed } from "@/utils/formatBalance";

const sliderList = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 25,
    label: "25%",
  },
  {
    value: 50,
    label: "50%",
  },
  {
    value: 75,
    label: "75%",
  },
  {
    value: 100,
    label: "MAX",
  },
];

const Detail = ({
  userScore,
  pool,
  onShowDetail,
  handleStake,
}: {
  userScore: number;
  pool: any;
  onShowDetail: () => void;
  handleStake: (poolId: number, score: number) => void;
}) => {
  const [inputValue, setInputValue] = useState("");
  const [sliderValue, setSliderValue] = useState(0);
  const [reward, setReward] = useState("0");

  useEffect(() => {
    if (!pool) return;

    const timer = setInterval(() => {
      getStakePoolReward(pool.poolId).then((res) => {
        setReward(res.data);
      });
    }, 2000);

    return () => clearInterval(timer);
  }, [pool]);

  return (
    <Box
      sx={{
        padding: "0 20px",
        color: "#fff",
      }}
    >
      <Flex
        sx={{
          marginTop: "20px",
        }}
        align={"center"}
      >
        <Flex
          justify={"center"}
          align={"center"}
          sx={{
            width: "26px",
            height: "26px",
            borderRadius: "50%",
            background: "#fff",
            marginRight: "10px",
          }}
        >
          <Image
            src={pool.img}
            sx={{
              width: "20px",
            }}
          ></Image>
        </Flex>
        <Text
          sx={{
            fontSize: "25px",
            fontStyle: "normal",
            fontWeight: 700,
          }}
        >
          {pool.tokenName} POOL
        </Text>
      </Flex>

      <Box
        sx={{
          marginTop: "20px",
        }}
      >
        <Flex
          flexDir={"column"}
          align={"center"}
          sx={{
            padding: "10px",
            background: "#232523",
            borderRadius: "15px",
            gap: "5px",
          }}
        >
          <Text
            sx={{
              color: "#818181",
              fontSize: "15px",
            }}
          >
            Reward:
          </Text>
          <Flex align={"center"}>
            <Flex
              align={"center"}
              justify={"center"}
              sx={{
                marginRight: "4px",
                background: "#fff",
                borderRadius: "50%",
                width: "26px",
                height: "26px",
              }}
            >
              <Image
                src={pool.img}
                sx={{
                  width: "18px",
                }}
              ></Image>
            </Flex>
            <Text
              sx={{
                fontSize: "25px",
              }}
            >
              {reward != "0" ? toFixed(reward, 4) : "0.00"}
            </Text>
          </Flex>
          <Flex align={"center"}>
            <Text
              sx={{
                color: "#7C7C7C",
                fontSize: "15px",
              }}
            >
              Your Staked:
            </Text>
            <Image
              src={TonIcon}
              sx={{
                width: "16px",
                marginRight: "2px",
                marginLeft: "5px",
              }}
            ></Image>
            <Text
              sx={{
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 900,
                color: "#7C7C7C",
              }}
            >
              {pool.userStake}
            </Text>
          </Flex>
        </Flex>
        <Box
          sx={{
            padding: "16px",
            background: "#232523",
            marginTop: "10px",
            borderRadius: "15px",
          }}
        >
          <Text sx={{ fontSize: "15px", fontWeight: 700, color: "#FBCC34" }}>
            Stake
          </Text>
          <Input
            variant={"unstyled"}
            value={inputValue}
            onChange={(e) => {
              // 如果输入为空,重置状态
              if (e.target.value === "") {
                setInputValue("");
                setSliderValue(0);
                return;
              }

              // 只允许输入整数
              const value = e.target.value.replace(/\D/g, "");
              if (!value) return;

              const amount = userScore;
              let rAmount = value;

              // 检查是否超过最大值
              if (parseInt(value) > Math.floor(amount)) {
                rAmount = String(Math.floor(amount));
              }

              // 计算百分比
              const rate = Math.floor(
                Number(accMul(accDiv(parseInt(rAmount), amount), 100))
              );

              setInputValue(rAmount);
              setSliderValue(rate);
            }}
            sx={{
              width: "100%",
              height: "38px",
              borderRadius: "12px",
              border: "2px solid #4C4C4C",
              background: "#4C4C4C",
              margin: "0 auto",
              color: "#fff",
              fontWeight: 700,
              paddingLeft: "12px",
              marginTop: "16px",
              "&::placeholder": {
                color: "#fff",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 700,
              },
            }}
            placeholder={"0.00"}
          ></Input>
          <Text
            sx={{
              fontSize: "15px",
              fontWeight: 700,
              marginTop: "9px",
              color: "#7C7C7C",
            }}
          >
            Balance: {userScore} $PEPES
          </Text>
          <Flex
            align={"center"}
            justify={"space-between"}
            sx={{
              marginTop: "18px",
            }}
          >
            {sliderList.map((item, index) => {
              return (
                <Flex
                  key={index}
                  sx={{
                    width: "47px",
                    height: "24px",
                    padding: "2px 9px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    flexShrink: 0,
                    borderRadius: "7px",
                    background: "#000",
                  }}
                >
                  <Text
                    sx={{
                      fontSize: "15px",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      const amount = userScore;
                      setInputValue(accMul(amount, item.value / 100));
                      setSliderValue(item.value);
                    }}
                  >
                    {item.label}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
          <Flex>
            <Slider
              id="slider"
              focusThumbOnChange={false}
              value={sliderValue}
              min={0}
              max={100}
              onChange={(v) => {
                const rate = Number(v) / 100;
                const amount = userScore;
                setInputValue(accMul(amount, rate));
                setSliderValue(v);
              }}
              sx={{
                marginTop: "20px",
              }}
            >
              <SliderTrack
                sx={{
                  background: "#DDD",
                  height: "13px",
                  borderRadius: "6px",
                }}
              >
                <SliderFilledTrack
                  sx={{
                    background: "#FBCC34",
                  }}
                />
              </SliderTrack>
              <SliderMark
                value={sliderValue}
                textAlign="center"
                sx={{
                  background: "transparent",
                  color: "#fff",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  textAlign: "center",
                  width: "40px",
                }}
                mt="3"
                ml="-4"
              >
                {sliderValue}%
              </SliderMark>
              <SliderThumb
                sx={{
                  background: "#FBCC34",
                  border: "2px solid #fff",
                  width: "22px",
                  height: "22px",
                }}
              />
            </Slider>
          </Flex>
          <GreenButton
            onClick={() => {
              handleStake(pool.poolId, parseInt(inputValue));
            }}
            sx={{
              height: "47px !important",
              marginTop: "30px",
              color: "#fff",
              fontWeight: 900,
            }}
          >
            Stake{" "}
          </GreenButton>
        </Box>
      </Box>
    </Box>
  );
};
export default Detail;
