import { api } from ".";

export const getMarketItems = async () => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get("/Charge/MarketItems", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const buyPoint = async (itemId: number) => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get(`/Charge/PayMarketChargeInfo/${itemId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
