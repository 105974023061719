import {
  Modal,
  Flex,
  ModalContent,
  ModalOverlay,
  Text,
  Box,
  Input,
  Image,
} from "@chakra-ui/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as ExportIcon } from "./assets/export.svg";
import { accGt, accGte } from "@/utils/formatBalance";
import ConfirmY from "@/components/Play/assets/confirm-y.png";
import toast from "react-hot-toast";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import CloseIcon from "./assets/close-w.png";

const WithdrawModal = ({
  token,
  isOpen,
  onClose,
  handleWithdraw,
}: {
  token: any;
  isOpen: boolean;
  onClose: () => void;
  handleWithdraw: (amount: number) => void;
}) => {
  const finalRef = useRef(null);

  const [tonConnectUI, setOptions] = useTonConnectUI();
  const rawAddress = useTonAddress(true);
  const [amount, setAmount] = useState("");

  const isWithdrawDisabled = useMemo(() => {
    if (!rawAddress) return true;

    if (!token) return true;

    if (amount == "0" || amount == "") return true;

    if (accGt(token?.minOut, amount)) return true;

    if (accGt(amount, token?.amount)) return true;

    return false;
  }, [amount, token, rawAddress]);

  useEffect(() => {
    setOptions({});
  }, []);

  return (
    <Modal
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      id="withdraw-modal"
    >
      <ModalOverlay sx={{ zIndex: 900 }} />
      <ModalContent
        containerProps={{
          sx: {
            zIndex: 900,
          },
        }}
        sx={{
          background: "#232523",
          width: "335px",
          border: "none",
          borderImage: "none",
          boxShadow: "none",
          borderRadius: "12px",
          padding: "16px",
        }}
      >
        <Flex align={"center"} justify={"space-between"}>
          <Text
            sx={{
              color: "#FBAE17",
              fontSize: "20px",
              fontWeight: 500,
            }}
          >
            REVIEW
          </Text>
          <Image
            onClick={onClose}
            src={CloseIcon}
            sx={{
              width: "20px",
              cursor: "pointer",
              height: "20px",
            }}
          ></Image>
        </Flex>

        <Text
          sx={{
            color: "#FFF",
            fontFamily: "Roboto",
            fontSize: "15px",
            fontWeight: 500,
            marginTop: "20px",
          }}
        >
          {token?.tokenName}
        </Text>
        <Box
          sx={{
            marginTop: "10px",
            background: "#000000",
            padding: "8px 20px",
            borderRadius: "12px",
          }}
        >
          <Flex align={"center"}>
            <Input
              variant="unstyled"
              value={amount}
              sx={{
                color: "#FFF",
                fontWeight: 500,
                fontSize: "30px",
              }}
              onChange={(e) => setAmount(e.target.value)}
            />
            <Flex
              sx={{
                borderRadius: "7px",
                background: "#FBAE17",
                width: "50px",
                height: "24px",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                setAmount(token?.amount);
              }}
            >
              MAX
            </Flex>
          </Flex>
          <Text
            sx={{
              fontSize: "10px",
              fontWeight: 500,
              color: "#7c7c7c",
              marginTop: "4px",
            }}
          >
            Balnce: {token?.amount}
          </Text>
        </Box>
        <Text
          sx={{
            color: "#FF004D",
            fontSize: "12px",
            fontWeight: 500,
            marginTop: "8px",
          }}
        >
          Minimum Withdrawal amount: {token?.minOut} ${token?.tokenName}
        </Text>
        <Flex sx={{ marginTop: "16px" }}>
          <Text
            sx={{
              color: "#FFF",
              fontSize: "13px",
              fontWeight: 500,
            }}
          >
            Network fee
          </Text>
          <Text
            sx={{
              color: "#FFF",
              fontSize: "13px",
              fontWeight: 500,
              marginLeft: "auto",
            }}
          >
            0.07 $TON
          </Text>
        </Flex>
        <Flex
          align={"center"}
          justify={"center"}
          sx={{
            width: "100%",
            marginTop: "28px",
            height: "36px",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
            borderRadius: "34px",
            border: "1px solid #FBAE17",
            cursor: isWithdrawDisabled ? "not-allowed" : "pointer",
            background: isWithdrawDisabled ? "transparent" : "#FBAE17",
          }}
          onClick={() => {
            if (!rawAddress) {
              tonConnectUI.openModal();

              return;
            }

            if (isWithdrawDisabled) {
              return;
            }
            handleWithdraw(Number(amount));
          }}
        >
          {rawAddress && (
            <ExportIcon
              style={{
                color: isWithdrawDisabled ? "#7C7C7C" : "#fff",
                width: "18px",
              }}
            ></ExportIcon>
          )}
          <Text
            sx={{
              color: !rawAddress
                ? "#fff"
                : isWithdrawDisabled
                ? "#7C7C7C"
                : "#fff",
              fontSize: "15px",
              fontWeight: 500,
            }}
          >
            {rawAddress ? "Withdraw" : "Connect Wallet"}
          </Text>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default WithdrawModal;
