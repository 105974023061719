import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Check from "./Check";
import Congrats from "./Congrats";
import Introduce from "./Introduce";
import Time from "./Time";
import { useInitData, useLaunchParams } from "@tma.js/sdk-react";
import { login } from "@/api";
import { useUserData } from "@/contexts/SubmitRequest";

const Start = ({ onInitChange }: { onInitChange: () => void }) => {
  const [step, setStep] = React.useState(0);
  const initData = useInitData();
  const launchParams = useLaunchParams();
  const { onUserDataChange, onFirstChange } = useUserData();

  const handleLogin = async () => {
    const { initDataRaw } = launchParams;
    const inviteUser = !isNaN(parseFloat(initData.startParam))
      ? parseFloat(initData.startParam)
      : 0;

    const simulationres: any = {
      // data: {
      //   expried: 1732868624,
      //   token:
      //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiRHVja3NfbGlmZSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWVpZGVudGlmaWVyIjoiNjgyNDk3NDg3NCIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvc2VyaWFsbnVtYmVyIjoiZjVlMjhmNDIyZWMzNDY3MWIwYWE2NjIyMjdlNGE4NTciLCJuYmYiOjE3MzI3ODIyMjQsImV4cCI6MTczMjg2ODYyNCwiaXNzIjoib3J6LnRvbnBlcGVzLnRlc3QuSXNzdWVyIiwiYXVkIjoib3J6LnRvbnBlcGVzLnRlc3QudXNlciJ9.eZwsjlcs1K8pFBdydJd5kRORJpqdZDJzblVWNxxjUJ4",
      //   user: {
      //     userId: 6824974874,
      //     userName: "Ducks_life",
      //     firstName: "cj",
      //     lastName: "999",
      //     score: 37608,
      //     isPremium: false,
      //     registerTime: 1697558400,
      //     firstLogin: false,
      //     year: 2,
      //   },
      // },
      // code: 200,
      // message: "OK",
    };

    try {
      let res = simulationres;
      if (!res.data) {
        res = await login({
          initData: initDataRaw,
          inviteUser: inviteUser,
        });
      }

      console.log(`inviteUser:${inviteUser}`);
      console.log(`userId:${res.data.user.userId}`);

      if (res.code === 200) {
        onUserDataChange(res.data.user);
        sessionStorage.setItem("jwtToken", res.data.token);
        const firstLogin = res.data.user.firstLogin;
        if (firstLogin == true) {
          // handleStep();
        } else {
          onInitChange();
        }
        onFirstChange();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleStep = () => {
    let _step = step + 1;
    if (_step === 4) {
      onInitChange();
      return;
    }
    setStep(_step);
  };

  useEffect(() => {
    if (!launchParams || !initData) {
      return;
    }
    handleLogin();
  }, [launchParams, initData]);

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      {step === 0 && <Introduce onStepChange={handleStep}></Introduce>}
      {step === 1 && <Check onStepChange={handleStep}></Check>}
      {step === 2 && <Time onStepChange={handleStep}></Time>}
      {step === 3 && <Congrats onStepChange={handleStep}></Congrats>}
    </Box>
  );
};

export default Start;
