import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Image,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import CloseIcon from "./assets/close.png";
import { chargeWithdraw, getUserAmountInfo } from "@/api/earn";
import { useEffect, useState } from "react";
import ExportIcon from "./assets/export-icon.png";
import WithdrawModal from "./WithdrawModal";
import ConfirmY from "@/components/Earn/assets/confirm-yy.png";
import toast from "react-hot-toast";
import { useTonAddress } from "@tonconnect/ui-react";
import { TokenImg } from "@/contexts/SubmitRequest";
import Export_fillIcon from "./assets/Export_fill.png";
import NoData from "./NoData";
const RewardDrawer = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const rawAddress = useTonAddress(true);

  const {
    isOpen: isWithdrawOpen,
    onOpen: onWithdrawOpen,
    onClose: onWithdrawClose,
  } = useDisclosure();

  const [tokensList, setTokensList] = useState<any[]>([
    {
      amount: "0",
      tokenName: "TON",
      jettonMaster: "",
      minOut: "0",
      decimals: 6,
    },
    {
      amount: "0",
      tokenName: "DOGS",
      jettonMaster: "",
      minOut: "0",
      decimals: 6,
    },
  ]);
  const [tokenName, setTokenName] = useState<string>("");

  const token = tokensList.find((item) => item.tokenName == tokenName);

  const handleSetTokenDetail = async (token: any) => {
    setTokenName(token.tokenName);
    onWithdrawOpen();
  };

  const handleWithdraw = async (amount: number) => {
    try {
      const res = await chargeWithdraw({
        jettonMaster: token?.jettonMaster,
        toAddress: rawAddress,
        amount: amount,
      });

      if (res.code == 200) {
        handleGetUserAmountInfo();
        toast(
          <Flex
            align={"flex-start"}
            sx={{
              padding: "0 0 0 12px",
              background: "#FBAE17",
              borderRadius: "10px",
              border: "none !important",
              overflow: "hidden",
              width: "100%",
            }}
          >
            <Flex
              className="test"
              sx={{
                textAlign: "center",
                background: "#232523",
                padding: "12px",
                margin: "0 !important",
                borderRadius: "10px",
                border: "none !important",
                color: "#fff",
                width: "100%",
              }}
              align={"flex-start"}
            >
              <Image
                src={ConfirmY}
                sx={{
                  width: "26px",
                  height: "26px",
                  marginRight: "8px",
                }}
              ></Image>
              <Box
                sx={{
                  flex: 1,
                }}
              >
                <Text
                  sx={{
                    fontSize: "15px",
                    fontStyle: "normal",
                    fontWeight: 900,
                    textAlign: "left",
                    marginTop: "4px",
                  }}
                >
                  Withdraw successfully
                </Text>
              </Box>
            </Flex>
          </Flex>,
          {
            duration: 3000,
            position: "top-center",
            style: {
              background: "transparent",
              borderRadius: "20px",
              padding: "0 0 0 0",
              margin: "0 !important",
              width: "100%",
              border: "none",
            },
          }
        );
      }
    } catch (e) {
      console.log("withdraw error", e);
    }
  };

  const handleGetUserAmountInfo = async () => {
    try {
      const res = await getUserAmountInfo();
      if (res.code == 200) {
        const _tokensList = [...tokensList];

        for (let i = 0; i < _tokensList.length; i++) {
          const token = _tokensList[i];
          const tokenInfo = res.data.find(
            (item: any) => item.tokenName == token.tokenName
          );
          if (tokenInfo) {
            _tokensList[i] = tokenInfo;
          }
        }

        setTokensList(_tokensList);
      }
    } catch (e) {
      console.log("getUserAmountInfo error", e);
    }
  };

  useEffect(() => {
    handleGetUserAmountInfo();
  }, []);

  return (
    <>
      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
        <DrawerOverlay sx={{ zIndex: 800 }} />
        <DrawerContent
          containerProps={{
            sx: {
              zIndex: 800,
            },
          }}
          sx={{
            padding: 0,
            background: "transparent",
            zIndex: 888,
          }}
        >
          <DrawerBody
            sx={{
              background: "#FBCC34",
              borderRadius: "20px 20px 0 0",
              padding: 0,
            }}
          >
            <Flex
              sx={{
                padding: "0 22px",
                height: "48px",
                paddingTop: "6px",
              }}
              align={"center"}
              justify={"space-between"}
            >
              <Text
                sx={{
                  color: "#000",
                  fontSize: "15px",
                  fontWeight: 500,
                }}
              >
                BALANCE
              </Text>

              <Image
                onClick={onClose}
                src={CloseIcon}
                sx={{
                  width: "20px",
                  cursor: "pointer",
                }}
              ></Image>
            </Flex>

            <Flex
              sx={{
                background: "#232523",
                padding: "22px",
                borderRadius: "20px 20px 0 0",
                minHeight: "calc(100vh - 100px)",
              }}
              flexDir={"column"}
              gap={"36px"}
            >
              {tokensList.map((item, index) => {
                const tokenImg =
                  TokenImg[item.tokenName as keyof typeof TokenImg];
                return (
                  <Flex key={index} align={"center"}>
                    <Flex
                      sx={{
                        width: "52px",
                        height: "52px",
                        background: "#fff",
                        borderRadius: "10px",
                        marginRight: "8px",
                      }}
                      align={"center"}
                      justify={"center"}
                    >
                      <Image
                        src={tokenImg}
                        sx={{
                          width: "36px",
                          height: "36px",
                        }}
                      ></Image>
                    </Flex>
                    <Box>
                      <Text
                        sx={{
                          color: "#7C7C7C",
                          fontSize: "15px",
                          fontWeight: 500,
                        }}
                      >
                        ${item.tokenName}
                      </Text>
                      <Text
                        sx={{
                          color: "#fff",
                          fontSize: "25px",
                          fontWeight: 500,
                        }}
                      >
                        {item.amount}
                      </Text>
                    </Box>
                    <Image
                      src={item.amount == "0" ? Export_fillIcon : ExportIcon}
                      sx={{
                        width: "44px",
                        marginLeft: "auto",
                      }}
                      onClick={() => {
                        if (item.amount == "0") return;
                        handleSetTokenDetail(item);
                      }}
                    ></Image>
                  </Flex>
                );
              })}

              {/* {tokensList.length === 0 && <NoData />} */}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <WithdrawModal
        token={token}
        isOpen={isWithdrawOpen}
        onClose={onWithdrawClose}
        handleWithdraw={handleWithdraw}
      ></WithdrawModal>
    </>
  );
};

export default RewardDrawer;
